<template>
	<div :class="{ 'plain-table': (enabled == false) } ">
		<slot></slot>
	</div>
</template>





<script>
/*
Ref: https://github.com/fiduswriter/Simple-DataTables
*/

export default {

	props: {
		
		/**
		 * The field's `id` value. Required.  
		 * Should be unique among all elements currently rendered on the page.
		 */
		id: {
			type: String,
			required: false
		},

		/**
		 * Additional CSS class(s) to apply.  
		 * Does NOT overwrite default classes.
		 */
		cssClass: {
			type: String
		},

		
		/**
		 * Increment the number when the table data has finished updating, 
		 * so the watcher will call loadDataTable()
		 */
		updater: {
			type: Number
		},



		/**
		 * Disable and display a normal table if necessary.
		 */
		enabled: {
			type: Boolean,
			default: false
		}
	},






	watch: {
		'updater': function(to, from)
		{
			//console.log("simple-datatable.watch(updater): from=", from, "to=", to, " | enabled=", this.enabled); 

			this.loadDataTable();
		},
	},





	data()
	{
		return {

		};
	},






	mounted()
	{
		
	},





	computed: {

	},





	methods: {
		init()
		{

		},


		loadDataTable()
		{
			//console.log("simple-datatable.loadDataTable() enabled=", this.enabled); 
			if (this.enabled.toString() == false.toString()) 	//toString() because <simple-datatable enabled="false">...</simple-datatable> is not bound attribute.
			{
				//console.log("stopping");
				return;
			}

			// setTimeout(() => {
			// 	//NOTE: SetTimeout to allow Vue time to load and render the data.
			// 	// const dataTable = new DataTable("#users-table", {
			// 	// 	searchable: true,
			// 	// 	fixedHeight: true,
			// 	// })
			// 	let myTable = document.querySelector("#" + this.id + "");
			// 	let dataTable = new DataTable(myTable);
			// }, 200);
		}
	}
}
</script>



<!-- 
// Original
<style scoped lang="scss">
	.plain-table
	{
		table,
		tbody
		{
			width: 100%;
			th
			{
				padding-right: 8px;
			}

			tr
			{
				td
				{
					padding-bottom: 8px;
					padding-right: 8px;
					font-size: 10pt;
				}
			}
		}
	}
</style>
 -->


<!--  
// Version 1

<style scoped lang="scss">

	.plain-table
	{
		:deep(table),
		:deep(tbody)
		{
			width: 100%;
			:deep(th)
			{
				padding-right: 8px;
			}

			:deep(tr)
			{
				:deep(td)
				{
					padding-bottom: 8px;
					padding-right: 8px;
					font-size: 10pt;
				}
			}
		}
	}
</style> -->


<style scoped lang="scss">
	.plain-table:deep
	{
		table,
		tbody
		{
			width: 100%;
			th
			{
				padding-right: 8px;
			}

			tr
			{
				td
				{
					padding-bottom: 8px;
					padding-right: 8px;
					// font-size: 10pt;
				}
			}
		}
	}
</style>







<style lang="scss">
// Ref: https://codepen.io/dcode-software/pen/dLVwOY
// Apply <table class="standard-table"> to the actual table in the hosting page.
.standard-table 
{
	border-collapse: collapse;
	margin: 25px 0;
	font-size: 0.9em;
	min-width: 400px;
	border-radius: 5px 5px 0 0;
	overflow: hidden;
	box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);

	width: 100%;
}

.standard-table thead tr th
{
	//background-color: #009879;		// Green
	//background-color: #0054a3;		// Blue 1
	background-color: #162f47;		// Blue 2
	//background-color: #585c61;		// Grey
	color: #ffffff;
	text-align: left;
	font-weight: bold;
}

.standard-table th,
.standard-table td 
{
	padding: 12px 15px;
}

.standard-table tbody tr 
{
	//border-bottom: 1px solid #dddddd;
	border-bottom: 1px solid rgba(22, 47, 71, .2);
}

.standard-table tbody tr td
{
	color: #000000;
	font-size: 1em;
	// font-weight: normal;
}

.standard-table tbody tr:nth-of-type(even) 
{
	// background-color: #f3f3f3;
}

.standard-table tbody tr:hover
{
	background-color: #fafafa;
}

.standard-table tbody tr:last-of-type 
{
	//border-bottom: 2px solid #009879;
	border-bottom: 2px solid #162f47;
}

.standard-table tbody tr.active-row
{
	font-weight: bold;
	//color: #009879;
	color: #162f47;
}








// Lighter header
.standard-table.standard-table-light-header thead tr th 
{
	//background-color: #a7b5c3;
	background-color: #dae9f9;
	color: #000000
}

</style>