//import Vue from 'vue/dist/vue';

// This 👇 produced runtime-core.esm-bundler.js:40 [Vue warn]: Component provided template option but runtime compilation 
// is not supported in this build of Vue. Configure your bundler to alias "vue" to "vue/dist/vue.esm-bundler.js".
// Ref: https://github.com/fengyuanchen/vue-feather/issues/8
//import { createApp } from 'vue'

import { createApp } from 'vue/dist/vue.esm-bundler';







import Rollbar from 'rollbar';


import globalStore from "./globalStore";
import config from "./config";
import api from "./api";				// Helpers to make calls to the API. All calls to the API must go through these helpers.
import api2 from "./api2";				// Helpers to make calls to the API. All calls to the API must go through these helpers.
import dateHelpers from "/helpers/dateHelpers";























































// // -------------------------------------------------------------------------------- //
// /**
//  * 	Setup Rollbar
//  */




// // console.log("config.GetEnvironmentCode()=", config.GetEnvironmentCode());

// var canLogRollbarInThisEnvironment = true;
// if (config.GetEnvironmentCode() == "development")
// {
// 	canLogRollbarInThisEnvironment = false;
// }

// if (canLogRollbarInThisEnvironment == true)
// {
// 	// Ref: https://docs.rollbar.com/docs/rollbarjs-configuration-reference

// 	// Set the Rollbar instance in the Vue prototype
// 	// before creating the first Vue instance.
// 	// This ensures it is available in the same way for every
// 	// instance in your app.
// 	Vue.prototype.$rollbar = new Rollbar({
// 		accessToken: '340f2a7dc2b140458875c541161119c3',
// 		captureUncaught: true,
// 		captureUnhandledRejections: true,
// 		payload: {
// 			environment: config.GetEnvironmentCode() + ":portal",
// 			person: {
// 				id: globalStore.state.currentUser.userId,
// 				username: globalStore.state.currentUser.displayName
// 				//email: globalStore.state.currentUser.userId
// 			}
// 		}
// 	});


// 	// If you have already set up a global error handler,
// 	// just add `vm.$rollbar.error(err)` to the top of it.
// 	// If not, this simple example will preserve the app’s existing
// 	// behavior while also reporting uncaught errors to Rollbar.
// 	Vue.config.errorHandler = (err, vm, info) => {
// 		vm.$rollbar.error(err);
// 		throw err; // rethrow
// 	};
// }







































// -------------------------------------------------------------------------------- //
// import VueRouter from 'vue-router';
// Vue.use(VueRouter);

import routes from "./routes.js";

// const router = new VueRouter({
// 	mode: 'history',		// Ref: https://router.vuejs.org/guide/essentials/history-mode.html#example-server-configurations
// 							//NOTE: When running this in Netlify, need add a redirect (see `netlify.toml` file)

// 	routes: routes
// });


import { createRouter, createWebHistory } from 'vue-router'

const router = createRouter({
	history: createWebHistory(import.meta.env.BASE_URL),
	routes: routes
})












let LotusGlobal = {
	// Set to true when the current route is a public (non-authenticated) view.
	// (see meta for each route in routes.js)
	currentRouteIsPublic: false,
	currentRouteName: "",
	currentRoutePath: ""
};

//https://router.vuejs.org/guide/advanced/navigation-guards.html#global-before-guards
//-- Global navigation guard
router.beforeEach((to, from, next) => {
	//console.log("router.beforeEach(): to", to);
	//console.log("router.beforeEach(): router.app", router.app);


	// Don't let it blow up below.
	if (!globalStore.currentUser) return;


	globalStore.currentUser.loadCurrentUser();
	// console.log("router.beforeEach(): globalStore.currentUser.isLoggedIn()=", globalStore.currentUser.isLoggedIn());

	var isPublic = (to.meta?.isPublic == true ? true : false);

	LotusGlobal.currentRouteIsPublic = isPublic;
	LotusGlobal.currentRouteName = to.name;
	LotusGlobal.currentRoutePath = to.fullPath;

	//var isAuthenticated = router.app.routeRequiresAuthenticationAndIsAuthenticated();
	var isAuthenticated = globalStore.currentUser.isLoggedIn();

	// console.log("router.beforeEach(): isPublic", isPublic);
	// console.log("router.beforeEach(): isAuthenticated", isAuthenticated);

	if (!isAuthenticated && !isPublic) 
	{
		// Not a public page  AND not logged in, so user needs to login.

		// console.log("globalStore.state.redirectAfterLoginPath = to.fullPath; == ", to.fullPath);

		// Set the route to redirect to after successful login.
		globalStore.state.redirectAfterLoginPath = to.fullPath;

		next({ path: '/login' })
	}
	else 
	{
		// Keep going.
		next();
	}

	// next();	//TODO: Remove. Obsolete.
});































// -------------------------------------------------------------------------------- //
/**
 * 	Load the app
 */

const appDefinition = {
	//router: router,


	//components: { VueCal },
	//components: { 'vue-cal': VueCal },







	data()
	{
		return {
			globalStore: globalStore,
	
			globalProcessingOverlay: 
			{
				show: false,
	
				triggerStatue: "off",
	
				count: 0		// How many times the overlay has been call to show at this time. Don't hide the overlay until this value is back to 1.
			},
	
			default_layout: "public",
	
			// toastMessage: {
			// 	id: "toast1",
			// 	show: true,
			// 	title: "",
			// 	message: "test message",
			// 	time: null
			// },
	
	
			toastMessages: [],
	
	
	
	
			completeAccessStructureForUser: {},
		};
	},


	






	created()
	{
		// console.log("app.created(): route=", this.$route);
		
		this.init();
	},








	

	mounted()
	{
		//this.checkCurrentAuthTokenStillValid();


		// console.log("app.mounted()");
		//console.log("app.mounted(): router=", this.$router)
		//console.log("app.mounted(): router.currentRoute=", this.$router.currentRoute)
		// console.log("app.mounted(): route=", this.$route)

		//TODO: Fuck this shit. I don't know how to access the current route from App
		//		so I'm just going to create a namespaces global variable and 
		//		set it from `router.beforeEach()`. FFS - why is everything that should be simple so fucking difficult?!

		this.registerKeyboardShortcuts();

		//this.showToastMessage();
	},










	watch: {
		$route(to, from) {
			// console.log("app.watch[route]: to", to);
			var pageTitle = to.meta.pageTitle ? "Lotus AI: " + to.meta.pageTitle : "Lotus AI"
			document.title = pageTitle;

			this.checkRequestedRoute();
		}
	},




	





	computed: {
		/**
		 * Returns the name of the layout component to use on the current page, 
		 * based on the `meta` property in the page's route configuration (routes.js). 
		 * See `<component :is="layout">` in index.html for usage.
		 * 
		 * @returns name of the layout component to use on the current page.
		 */
		layout()
		{
			if (!this.$route.meta.layout) return "";	// Probably still performing app setup. Don't return anything.

			//console.log("app.layout(): ", this.$route.meta.layout);
			var layoutElement = (this.$route.meta.layout || this.default_layout) + "-layout";

			//TODO: Just for some easy dev testing
			if (layoutElement == "primary-layout") layoutElement = "primary2-layout";

			return layoutElement;
		},



		/**
		 * Which background CSS blass to apply to the body (based on layout)
		 */
		bodyBackgroundClass()
		{
			if (!this.$route.meta.layout) return "bg-primary";	// Probably still performing app setup. Don't return anything.



			////console.log("app.layout(): ", this.$route.meta.layout);
			//return (this.$route.meta.layout || this.default_layout) + "-layout";

			if (this.$route.meta.layout == "no") return "";

			// Default
			return "bg-primary";
			
		}
	},










	methods: {
		init()
		{	
			// console.log("app.init(): mod globalStore=", globalStore);

			//this.checkRequestedRoute();	//TODO: No - do it via the watch. The initial from here has fuck all in the $route/
			this.autoLogin();

			this.getCompleteAccessStructureForRequestingUser();

			this.setBodyBackgroundClass();

			// console.log("app.init(): finished");
		},





		/**
		 * Which background CSS blass to apply to the body (based on layout)
		*/
		setBodyBackgroundClass()
		{
			//let bgClass = "bg-primary";	// Default
			let bgClass = "bg-primary-override";	// Default

			//console.log("setBodyBackgroundClass(): this.$route.meta.layout=", this.$route.meta.layout);
			//console.log("setBodyBackgroundClass(): this.$route=", this.$route);

			if (!this.$route.meta.layout)
			{
				// Nothing here
			}
			else
			{
				if (this.$route.meta.layout == "no") bgClass = "";
			}

			//console.log("setBodyBackgroundClass(): bgClass=", bgClass);
			document.body.classList.add(bgClass);
		},



		/**
		 * Check the requested route and perform any redirects is required.
		 */
		checkRequestedRoute()
		{
			//console.log("checkRequestedRoute(): this.$route=", this.$route);
			if (this.$route.meta.redirectCode)
			{
				var redirectCode = this.$route.meta.redirectCode;


				//Note: I'm defining redirects by a "code" in the routers (vs defining the actual redirect destination)
				//so we can add any additional logic in this function.
				if (redirectCode.toLowerCase() == "home")
				{
					this.redirectToPath("/home");
					//this.pushToPath("/home");
				}
				if (redirectCode.toLowerCase() == "edit-timesheet")
				{
					this.redirectToPath("/timesheets/id/" + this.$route.params["id"] + "/edit");	//Not checking "id" param exits. It should. If not then someone's fucking with it.
				}
			}
		},




		/**
		 * Check for remembered user data and auto login the user.
		 */
		autoLogin()
		{
			// Don't let it blow up below.
			if (!globalStore.currentUser) return;

			this.globalStore.currentUser.loadCurrentUser();
			
			if (canLogRollbarInThisEnvironment == true)
			{
				//-- Set the person in Rollbar
				var _this = this;
				this.$rollbar.configure({
					payload: {
						person: {
							id: _this.globalStore.state.currentUser.userId,
							username: _this.globalStore.state.currentUser.displayName
							//email: _this.globalStore.state.currentUser.userId
						}
					}
				});
			}


			//console.log("app.autoLogin(): globalStore.state.currentUser=", this.globalStore.state.currentUser);

			//this.authoriseAccess();
		},





		checkCurrentAuthTokenStillValid()
		{
			var _this = this;
			
			var requestData = {
			};


			this.$root.callApi("/Authenticate/GetCurrentUserAuthentication", requestData, "POST")
			.then((responseData) => {
				//console.log("app.checkCurrentAuthTokenStillValid(): responseData=", responseData);

				if (responseData == null) return; // Not successful

				_this.getCurrentRoute();

				if (responseData.IsAuthenticated == false)
				{
					// User it not logged in, or their session has expired (or we've switched databases in a test environment)

					_this.globalStore.state.redirectPath = _this.getCurrentRoute();

					_this.showFailToastMessage("You are not currently logged in, or your session has expired.<br>Please login.", "Not Logged In");
					_this.redirectToLogin(true);
				}

				// User still logged in.
			});
		},





		

		/**
		 * Retrieve an up to date list of access control data from the server for the user.
		 */
		getCompleteAccessStructureForRequestingUser()	
		{
			//console.log("getCompleteAccessStructureForRequestingUser(): LotusGlobal=", LotusGlobal);

			// It's a public route so not authenticated, which means we don't have a user to get the access structure for.
			if (LotusGlobal.currentRouteIsPublic) 
			{
				//console.log("getCompleteAccessStructureForRequestingUser(): PUBLIC. SKIPPING!");
				return;
			}

			var _this = this;
			//console.log("--- this IS:", this);


			_this.completeAccessStructureForUser = {};

			// Check the last time it was retrieved, so we're not too aggressive.
			// Only once per 60 seconds to start. Can probably get it down to once per hour later.

			//TODO: this isn't working properly. completeAccessStructureForUserLastRetrievedUtc is not being saved and is always null between pages.
			
			//console.log("app.getAccessControlDefinitions(): this.completeAccessStructureForUserLastRetrievedUtc=", this.globalStore.state.app.completeAccessStructureForUserLastRetrievedUtc);
			//NOTE: completeAccessStructureForUserLastRetrievedUtc comes back as null all the time, but maybe that's a delay in calling the autoLogin() in the init() function.
			//		Now I think about it, this is being called on every page/route load... that's a lot of unnecessary loading.

			var lastAccessed = new Date(2020, 1, 1, 0, 0, 0).getTime();	// Guarantee it's run the first time.
			//console.log(">>>>> this.globalStore=", this.globalStore);
			if (this.globalStore.state && this.globalStore.state.app)
			{
				if (this.globalStore.state.app.completeAccessStructureForUserLastRetrievedUtc != null)
				{
					lastAccessed = this.globalStore.state.app.completeAccessStructureForUserLastRetrievedUtc;
				}
			}
			var nowMilliseconds = new Date().getTime();
			//console.log("app.getCompleteAccessStructureForRequestingUser(): nowMilliseconds=", nowMilliseconds);
			//console.log("app.getCompleteAccessStructureForRequestingUser(): lastAccessed=", lastAccessed);

			var diffSeconds = (nowMilliseconds - lastAccessed) / 1000;

			//console.log("app.getCompleteAccessStructureForRequestingUser(): diffSeconds=", diffSeconds);

			this.globalStore.state.app.completeAccessStructureForUserLastRetrievedUtc = nowMilliseconds;

			//TDOO: Disable for dev testing
			if (diffSeconds < 60)
			{
				//console.log("app.getCompleteAccessStructureForRequestingUser(): too soon. Only " + diffSeconds + " seconds since last retrieval.");
				return;	// Too soon
			}



			//console.log("_this IS:", _this);
			this.$root.callApi("/AccessControl/GetCompleteAccessStructureForRequestingUser", {}, "GET")
			.then((responseData) => {
				_this.isLoading = false;

				//console.log("app.getCompleteAccessStructureForRequestingUser(): responseData=", responseData);
				
				if (responseData == null) return;	// Not successful

				//_this.$set(_this, 'completeAccessStructureForUser', responseData);
				_this.completeAccessStructureForUser = responseData.Data;

				//console.log("app.getCompleteAccessStructureForRequestingUser(HERE1): this.completeAccessStructureForUser=", _this.completeAccessStructureForUser);

				if (_this.completeAccessStructureForUser)
				{
					_this.globalStore.currentUser.setCurrentUserCompleteAccessStructure(_this.completeAccessStructureForUser);
				}
				
			});
		},



		
		validateAuthToken(authToken)
		{
			var _this = this;
			
			let promise = new Promise(function(resolve, reject) {	
			
				var requestData = {
					authToken: authToken
				};
	
	
				_this.callApi("/Authenticate/ValidateAuthToken", requestData, "POST")
				.then((responseData) => {
					// console.log("app.validateAuthToken(" + authToken + "): responseData=", responseData);

					if (responseData == null) 
					{
						resolve(null); // Not successful
					}
					else
					{
						resolve(responseData);
					}
				});

			});

			return promise;
		},





		//TODO: Disabled. Auth checks need to run on each page (or route) anyway.
		//TODO: This only runs on initial page load. Authorisation still needs to be run when accessing each page (or each layout)
		authoriseAccess()
		{
			// console.log("app.authoriseAccess()");

			// // If trying to access the login page, allow them regardless of login state
			// var route = this.$router.currentRoute.path;
			// route = route.toLowerCase();
	
			// if (
			// 	route == config.loginRootRelativePath.toLowerCase() || 
			// 	route == config.logoutRootRelativePath.toLowerCase() ||
			// 	route == config.forgotPasswordRootRelativePath.toLowerCase() ||
			// 	route == config.resetPasswordRootRelativePath.toLowerCase()
			// 	)
			// {
			// 	// Continue. Also the only publicly accessible pages.
			// }
			// else
			// {
			// 	if (this.globalStore.currentUser.isLoggedIn() == false)
			// 	{
			// 		// Not logged in. Direct to the login page.
			// 		this.redirectToLogin(true);
			// 	}
			// 	else
			// 	{
			// 		// Is logged in. Continue to requested page.
			// 	}
			// }

			if (this.routeRequiresAuthenticationAndIsAuthenticated())
			{
				// Not logged in. Direct to the login page.
				this.redirectToLogin(true);
			}
		},





		/**
		 * Check if the current route requires authentication and if the user is currently authenticated.
		 */
		routeRequiresAuthenticationAndIsAuthenticated()
		{
			// console.log("app.requiresAuthenticationAndIsAuthenticated()");

			// If trying to access the login page, allow them regardless of login state
			//var route = this.$router.currentRoute.path; //TODO: Obsolete
			var route = this.$route.path;
			route = route.toLowerCase();
	
			if (
				route == config.loginRootRelativePath.toLowerCase() || 
				route == config.logoutRootRelativePath.toLowerCase() ||
				route == config.forgotPasswordRootRelativePath.toLowerCase() ||
				route == config.resetPasswordRootRelativePath.toLowerCase()
				)
			{
				// Continue. Also the only publicly accessible pages.
			}
			else
			{
				if (this.globalStore.currentUser.isLoggedIn() == false)
				{
					// Not logged in. Direct to the login page.
					return true;
				}
				else
				{
					// Is logged in. Continue to requested page.
					return false;
				}
			}
		},















		registerKeyboardShortcuts()
		{
			// Ref: https://shubhamjain.co/til/vue-shortcuts/

			var _this = this;

			document.onkeydown = function(e) {
				//console.log("onKeyDown=", e);
				// if (e.shiftKey)
				// {
				// 	alert("shift!");
				// }

				// Dev screens
				if (e.key === "D" && (e.ctrlKey || e.metaKey)) 	// Ctrl + Shift + "d" (shift+"d" == "D")
				{
					e.preventDefault();
			
					//alert("The dev shortcut was pressed");
					_this.redirectToPath( "/system-admin" );
				}


				// Cheat mode
				if (e.key === "_" && (e.ctrlKey || e.metaKey)) 	// Ctrl + Shift + "-" (shift+"-" == "_")
				{
					var code = prompt("Please enter your name", "");

					if (code.toLowerCase() == "iddqd")
					{
						alert("GOD MODE ACTIVATED!");
					}
					if (code.toLowerCase() == "idkfa")
					{
						alert("LOCKED AND LOADED!");
					}
				}
			};
		},















		//NOTE: Encapsulate redirect logic in on location for easy modification.
		/**
		 * Redirect to a specified path location.
		 */
		redirectToPath(path)
		{
			
			
			//console.log("app.redirectToPath(): path=", path);
			

			//TODO: NOTE: This is not updating the displayed page title using the item's `pageTitle` set in the routes.js.
			// router.push({ path: path })
			// .catch(()=>{});	//To remove "vue "Error: Avoided redundant navigation to current location" (ref: https://stackoverflow.com/questions/62462276/how-to-solve-avoided-redundant-navigation-to-current-location-error-in-vue/62465003)


			window.location.href = path;		//TODO: We could just go plain ol' vanilla JS. This does ensure the page title is updated (in testing so far [15 May 2021])
		},



		pushToPath(path)
		{
			//console.log("app.pushToPath(): path=", path);
		

			//TODO: NOTE: This is not updating the displayed page title using the item's `pageTitle` set in the routes.js.
			router.push({ path: path })
			// .catch(()=>{});	//To remove "vue "Error: Avoided redundant navigation to current location" (ref: https://stackoverflow.com/questions/62462276/how-to-solve-avoided-redundant-navigation-to-current-location-error-in-vue/62465003)
		},


		redirectToLogin(redirecToThisRouteAfterLogin = true)
		{
			if (redirecToThisRouteAfterLogin == true)
			{
				//console.log("this.globalStore.state.redirectAfterLoginPath = this.getCurrentRoute(); ==", this.getCurrentRoute());
				this.globalStore.state.redirectAfterLoginPath = this.getCurrentRoute();
			}
			
			router.push({ path: "/login" });
		},

		redirectToHomePage()
		{
			router.push({ path: "/home" });
		},





		//Example:
		// callApi(endpoint, data, verb = "GET")
		// {
		// 	return apiCall.callApi(endpoint, data, verb);
		// },
		//
		//
		/**
		 * Proxy/helper to call the Lotus API. 
		 * Call this from components via `this.$root.callApi(...)` to save importing api.js into every component.
		 * 
		 * @param {string} endpoint Lotus API endpoint to call.
		 * @param {object} data Request data to send to the API.
		 * @param {string} verb HTTP verb to use ("GET" or "POST").
		 * @param {object} defaultRejectResponse If the request fails and a Reject is raised, the data to return. Default is `null`, but set to an expected "no results" value the caller can action without a need for a null check.
		 */
		callApi(endpoint, data, verb, defaultRejectResponse = null)
		{
			var _this = this;

			let promise = new Promise(function(resolve, reject) 
			{
				var apiResult = api.callApi(endpoint, data, verb);

				apiResult.then((responseData) => {

				if (responseData.status == "success")
				{
					// console.log("app.callApi(" + endpoint + ")[success]: responseData=", responseData);
					resolve(responseData.data);
				}
				else
				{
					//-- "Fail" status. Something went wrong and no API data to return.

					if (responseData.failDisplayMessage != "")
					{
						_this.showFailToastMessage(responseData.failDisplayMessage, responseData.failDisplayTitle);
					}

					if (responseData.logWarning == true)
					{
						_this.logWarning("API request failed", 
							{ 
								ApiVerb: verb,
								ApiEndPoint: endpoint,
								RequestData: data,
								LotusStatusCode: responseData.lotusStatusCode,
								LotusStatusDetails: responseData.lotusStatusDetails,
								ResponseData: responseData
							}
						);
					}

					// `reject()` is wrong. The caller was never expected to handle a reject. That's why we have this middleware function.
					// Always `resolve()`, and in the case of a faulure the value sent back to the caller should be `null`.
					// reject(defaultRejectResponse);	// Default is `null`

					resolve(defaultRejectResponse);	// Default is `null`
				}
				});
	
			});

			return promise;
		},




		/**
		 * Proxy/helper to call the Lotus API. 
		 * Call this from components via `this.$root.callApi(...)` to save importing api.js into every component.
		 * 
		 * Version 2: Implements and handles `reject()`, and automatically shows and hides the processing overlay.
		 * 
		 * @param {string} endpoint Lotus API endpoint to call.
		 * @param {object} data Request data to send to the API.
		 * @param {string} verb HTTP verb to use ("GET" or "POST").
		 * @param {object} defaultRejectResponse If the request fails and a Reject is raised, the data to return. Default is `null`, but set to an expected "no results" value the caller can action without a need for a null check.
		 */
		callApi2WithOverlay(verb, endpoint, data, defaultRejectResponse = null)
		{
			var _this = this;

			// console.log("callApi2WithOverlay(): verb=", verb, endpoint, data);

			this.showProcessingOverlay();

			let promise = new Promise(function(resolve, reject) 
			{
				// console.log("callApi2WithOverlay(): Stage 1");

				var apiResult = api2.callApi(endpoint, data, verb);

				// console.log("callApi2WithOverlay(): Stage 2. apiResult=", apiResult);

				apiResult
				.then((responseData) => {
					// console.log("callApi2WithOverlay(): Then");
					_this.hideProcessingOverlay();

					// console.log("app.callApi(success): responseData=", responseData);
					// At this point we are expecting `responseData.status == "success"` returned from api2 handler.
					// Anything else will be handledi n the `catch()`.
					resolve(responseData.data);
				})
				.catch((responseData) => {
					// Something (anything) failed in the API call.
					// console.log("callApi2WithOverlay(): Catch. responseData=", responseData);
					
					_this.hideProcessingOverlay();


					//-- "Fail" status. Something went wrong and no API data to return.

					if (responseData.failDisplayMessage != "")
					{
						_this.showFailToastMessage(responseData.failDisplayMessage, responseData.failDisplayTitle);
					}

					if (responseData.logWarning == true)
					{
						_this.logWarning("API request failed", 
							{ 
								ApiVerb: verb,
								ApiEndPoint: endpoint,
								RequestData: data,
								LotusStatusCode: responseData.lotusStatusCode,
								LotusStatusDetails: responseData.lotusStatusDetails,
								ResponseData: responseData
							}
						);
					}

					// `reject()` is wrong. The caller was never expected to handle a reject. That's why we have this middleware function.
					// Always `resolve()`, and in the case of a faulure the value sent back to the caller should be `null`.
					// reject(defaultRejectResponse);	// Default is `null`

					//-- In Version 2 we're going to reject, so we don't need any checks in the "happy path" flow
					//   of the callier (i.e in `.then()`). The default assumption in the caller should always be
					//   "everything is good", and when it's not it will be handled at this level or lower.
					//resolve(defaultRejectResponse);	// Default is `null`
					reject(defaultRejectResponse);	// Default is `null`
				});

			});

			// console.log("callApi2WithOverlay(): promise=", promise);

			return promise;
		},





		//Example:
		// callApi(endpoint, data, verb = "GET")
		// {
		// 	return apiCall.callApi(endpoint, data, verb);
		// },
		//
		//
		/**
		 * Proxy/helper to call the Lotus "Files" API. 
		 * Call this from components via `this.$root.callFilesApi(...)` to save importing api.js into every component.
		 * 
		 * @param {string} endpoint Files API endpoint to call.
		 * @param {object} data Request data to send to the API.
		 * @param {string} verb HTTP verb to use ("GET" or "POST").
		 * @param {object} defaultRejectResponse If the request fails and a Reject is raised, the data to return. Default is `null`, but set to an expected "no results" value the caller can action without a need for a null check.
		 */
		callFilesApi(endpoint, data, verb, defaultRejectResponse = null)
		{
			var _this = this;

			let promise = new Promise(function(resolve, reject) 
			{
				var apiResult = api.callFilesApi(endpoint, data, verb);

				apiResult.then((responseData) => {

				if (responseData.status == "success")
				{
					// console.log("app.callApi(success): responseData=", responseData);
					resolve(responseData.data);
				}
				else
				{
					//-- "Fail" status. Something went wrong and no API data to return.

					if (responseData.failDisplayMessage != "")
					{
						_this.showFailToastMessage(responseData.failDisplayMessage, responseData.failDisplayTitle);
					}

					if (responseData.logWarning == true)
					{
						_this.logWarning("API request failed", 
							{ 
								ApiVerb: verb,
								ApiEndPoint: endpoint,
								RequestData: data,
								LotusStatusCode: responseData.lotusStatusCode,
								LotusStatusDetails: responseData.lotusStatusDetails,
								ResponseData: responseData
							}
						);
					}

					// `reject()` is wrong. The caller was never expected to handle a reject. That's why we have this middleware function.
					// Always `resolve()`, and in the case of a faulure the value sent back to the caller should be `null`.
					// reject(defaultRejectResponse);	// Default is `null`

					resolve(defaultRejectResponse);	// Default is `null`
				}
				});
	
			});

			return promise;
		},



		














		
		/**
		 * Return a value of a route parameter for a given key (defined in "/routes.js").
		 */
		getRouteValue(key)
		{
			//var params = this.$router.currentRoute.params; //TODO: Obsolete
			var params = this.$route.params;
			
			return params[key] ? params[key] : "";
		},
		getCurrentRouteParam(key)	//This should replace getRouteValue()
		{
			var params = this.$route.params;
			
			return params[key] ? params[key] : "";
		},


		/**
		 * Get a value from the URL query string. Case insensitive.
		 */
		getQueryValue(key)
		{
			//var query = this.$router.currentRoute.query; //TODO: Obsolete
			var query = this.$route.query;

			for (var prop in query)
			{
				if (prop.toLowerCase() == key.toLowerCase()) return query[prop];
			}

			return "";
			
			//return query[key] ? query[key] : "";	// Case sensitive
		},




		getCurrentRoute()
		{
			// console.log("getCurrentRoute(): $router.currentRoute=", this.$router.currentRoute);

			//return this.$router.currentRoute.fullPath; //TODO: Obsolete
			return this.$route.fullPath;
		},


		getCurrentRoutePath()
		{
			// console.log("getCurrentRoute(): $router.currentRoute=", this.$router.currentRoute);
			//console.log("this.$router.currentRoute.path=", this.$router.currentRoute.path);
			//console.log("this.$route=", this.$route);

			//var path = this.$router.currentRoute.path; //TODO: Obsolete
			var path = this.$route.path;
			path = path.toLowerCase();
			
			return path;
		},



		getCurrentRouteName()
		{
			//const routeName = this.$router.currentRoute.name; //TODO: Obsolete
			const routeName = this.$route.name;

			return routeName;
		},


		/**
		 * Check if the supplied name matched the name of the current Vue Route.
		 * 
		 * @param {string} name Name of the route to check.
		 * 
		 * @returns {boolean} True if the name matches, otherwise false.
		 */
		isCurrentRouteName(name)
		{
			//var routeName = this.$router.currentRoute.name;	//TODO: Obsolete
			var routeName = this.getCurrentRouteName();

			// No name defined
			if (routeName == undefined) routeName = "";

			//
			if (routeName.toLowerCase() == name.toLowerCase()) return true;

			return false;
		},





		









		showProcessingOverlay(firstCall)
		{
			//console.log("----- showProcessingOverlay() Trace: ")
			//console.trace();	// For debugging. hideProcessingOverlay() not being called. Need to see what this caller is.


			if (firstCall == true)
			{
				this.globalProcessingOverlay.count = 1;
			}
			else
			{
				this.globalProcessingOverlay.count = this.globalProcessingOverlay.count  + 1;
			}

			//console.log("~~~~~ showProcessingOverlay(): this.globalProcessingOverlay.count=" + this.globalProcessingOverlay.count);

			if (this.globalProcessingOverlay.show == true)
			{
				// Already showing the overlay. Don't continue.
			}

			var _this = this;

			this.globalProcessingOverlay.triggerStatue = "waiting";


			
			// Delay processing so we don't get a flash on really short processes.
			setTimeout(function() {
				if (_this.globalProcessingOverlay.triggerStatue == "finished")
				{
					// The hide state has already been called before showing the overlay.
					// Reset for a new request to show the overlay. Do nothing else.
					_this.globalProcessingOverlay.triggerStatue = "";
					_this.globalProcessingOverlay.count = _this.globalProcessingOverlay.count - 1;
				}
				else if (_this.globalProcessingOverlay.triggerStatue == "waiting")
				{
					// The request to the overlay was triggered.
					// It hasn't finished already, so we can show it.
					_this.globalProcessingOverlay.triggerStatue = "showing";
					_this.globalProcessingOverlay.show = true;
				}
				else if (_this.globalProcessingOverlay.triggerStatue == "showing")
				{
					// Overlay is already being shown for displaying.
					// Do nothing here.
				}
				
			}, 200);
		},


		hideProcessingOverlay()
		{
			//console.log("***** hideProcessingOverlay(): this.globalProcessingOverlay.count=" + this.globalProcessingOverlay.count);
			//console.trace();	// For debugging. hideProcessingOverlay() not being called. Need to see what this caller is.

			if (this.globalProcessingOverlay.count > 1)
			{
				// Multiple processes currently require the overlay. Decrease for this finished process, but keep showing for other required processes.
				this.globalProcessingOverlay.count = this.globalProcessingOverlay.count - 1;
				return;
			}

			// Overlay display is complete. Reset ready for a new overlay display request.
			this.globalProcessingOverlay.show = false;
			this.globalProcessingOverlay.triggerStatue = "finished";

			this.globalProcessingOverlay.count = this.globalProcessingOverlay.count - 1;
		},













		// Ref: https://getbootstrap.com/docs/4.3/components/toasts/
		showToastMessageHandler(title, message, messageType)
		{
			//console.log("showToastMessage(): this.toastMessage.id=", this.toastMessage.id);
			
			var toastMessage = {
				id: "",
				message: "",
				title: "",
				time: null,
				created: null,
				messageType: messageType
			};
			toastMessage.id = "toast-" + dateHelpers.getDateTimestamp();
			toastMessage.message = message;
			toastMessage.title = title;
			toastMessage.created = new Date().getTime();

			//this.toastMessage = toastMessage;
			//$("#" + this.toastMessage.id).toast('show');

			this.toastMessages.push(toastMessage)

			// Timeout to give the UI time to render. Maybe use nextTick instead?
			setTimeout(function() { 
				$("#" + toastMessage.id).toast('show');	// Bootstrap toasts
			}, 500);


			//TODO: Clean-up old toasts (?), based on `create` and the display duration.
		},

		showToastMessage(message, title = "Notification")
		{
			console.log("showToastMessage(): ", title, message);
			this.showToastMessageHandler(title, message, "message"); 
		},

		showSuccessToastMessage(message, title = "Notification")
		{
			console.log("showSuccessToastMessage()");
			this.showToastMessageHandler(title, message, "success");
		},

		showFailToastMessage(message, title = "Notification")
		{
			this.showToastMessageHandler(title, message, "fail");
		},
















		logError(message, extraData)
		{
			if (this.$rollbar == undefined) return;	// Not active in this environment

			this.$rollbar.error(message, extraData);
		},


		logWarning(message, extraData)
		{
			if (this.$rollbar == undefined) return;	// Not active in this environment

			this.$rollbar.warn(message, extraData);
		},


		logInfo(message, extraData)
		{
			if (this.$rollbar == undefined) return;	// Not active in this environment
			
			this.$rollbar.info(message, extraData);
		},





		//TODO: Nope. `.modal('hide');` means a modal cannot be naturall toggled again. Add modal dismiss to the primary action.
		// /**
		//  * Programmatially hide a Bootstrap generated modal (and background overlay).
		//  * 
		//  * @param {string} id ID of the modal's main element.
		//  */
		// hideBootstrapModal(id)
		// {
		// 	$('#' + id + '').modal('hide');

		// 	// Hide the overlay Ref: https://stackoverflow.com/questions/22056147/bootstrap-modal-backdrop-remaining?rq=1#:~:text=Make%20sure%20you%20clear%20'showing'%20at%20the%20point%20it's%20shown.&text=I%20got%20the%20same%20problem,you%20are%20going%20to%20click.&text=Add%20data%2Dbackdrop%3D%22false,to%20remove%20bootstrap%20modal%20overlay%3F
		// 	$('.modal-backdrop').remove();
		// }













		/**
		 * Takes an array of objects and returns an array of object ready for binding to a select list.
		 * @param {array} data The array of object to extract the select this value/label pairs from.
		 * @param {string} valueProperty The object property in `data` containing the value for each item in the list.
		 * @param {string} textProperty The object property in `data` containing the text/label for each item in the list.
		 * @param {string} defaultLabel (optional) The first (default) item in the list to select when an actual value hasn't been selected (e.g. placeholder item). The corresponding value is "".
		 * @returns array of objects with property `value` for the item value, and `text` for the item text/label.
		 */
		getSelectOptionsFromData(data, valueProperty, textProperty, defaultLabel = "")
		{
			if (!data) return [];
			var options = [];

			for (var i = 0; i < data.length; i++)
			{
				options.push(
					{ 
						value: data[i][valueProperty], 
						text: data[i][textProperty] 
					}
				);
			}


			if (defaultLabel != "")
			{
				options.unshift({ 
					value: "", 
					text: defaultLabel 
				})
			}

			return options;
		},






		//TODO: Obsolete. Don't use for new work.
		/**
		 * **[ DO NOT USE!! ]**  
		 * Is the current user a Lotus "System Administrator".
		 *   
		 * System Admin is an original user role, made obsolete 21 Aug 2021 with the 
		 * introduction of feature access flags.  
		 * 
		 * Don't use for any future work (and refactor it out eventually). Keeping the function during 
		 * initial development cross-over to feature access flags.
		 * 
		 * @returns True if the current user is a system admin.
		 */
		isSystemAdminUser()
		{
			return ( this.hasAccess("LotusDevelopment.All") || this.hasAccess("LotusAdministration.All") );
		},


		isAccountAdminUser()
		{
			return ( this.hasAccess("AccountAdmin.All") );
		},




		/**
		 * Is the current user logged into the Lotus account?
		 * 
		 * @param String accountId Optional ID of the account to check. If not set then default to `globalStore.state.currentUser.activeAccountId`.
		 * 
		 * @returns true if logged into Lotus account.
		 */
		isLotusAccount(accountId)
		{
			//No account ID specified, so default to the current users's account.
			if (!accountId) accountId = this.globalStore.state.currentUser.activeAccountId;

			var isLotusAccount = accountId.toLowerCase() == "875E733C-09AA-EA11-9B04-0050F297923B".toLocaleLowerCase();

			//console.log("isLotusAccount=", isLotusAccount);

			return isLotusAccount;
		},


		/**
		 * Is the current session where a Lotus user is aliasing another user?
		 * 
		 * @returns true if the current session is a Lotus user aliasing another user.
		 */
		isLotusAlias()
		{
			return globalStore.state.currentUser.isAliasLogin;
		},


		/**
		 * Is the current user a member of the Lotus account, either running in the Lotus account or aliasing into another account?
		 * 
		 * @returns returns `true` if the current user is a Lotus user, otherwise false.
		 */
		isLotusUser()
		{
			return this.isLotusAccount() || this.isLotusAlias();
		},




		isProduction()
		{
			return config.IsProduction();
		},


		isDevelopment()
		{
			//return config.GetEnvironmentCode() == "development";

			//console.log("config.GetEnvironmentCode()=", config.GetEnvironmentCode());

			// Is Lotus so we can switch and test when viewing as customers.
			return config.GetEnvironmentCode() == "development" && this.isLotusAccount();
		},




		getEnvironmentCode()
		{
			return config.GetEnvironmentCode();
		},



		isTest()
		{
			return config.IsTest();
		},

		/**
		 * Check if a user can access a feature.  
		 *   
		 * This is the heart of security feature and access control and
		 * the same control set of data is used in the API.
		 * @param {string} featureCode Code of the feature checking access
		 * @returns True if the feature Access value is "Enabled", otherwise false for "Disabled".
		 */
		hasAccess(featureCode)
		{
			// //TODO: Some quick manual rules while waiting to implement the actual profiles and features in the back-end and setup the accounts.
			// if (featureCode.toLowerCase() == "module.desktoptimesheets.enabled")
			// {
			// 	// The original module. On for everyone.
			// 	return true;
			// }

			// if (featureCode.toLowerCase() == "quoting.enabled")
			// {
			// 	// Just for Lotus for now
			// 	if (this.isLotusAccount()) return true;
			// 	return false;
			// }

			
			// Don't blow up. Might happen after updating data structures.
			if (!this.globalStore.currentUser) return false;

			// console.log("App.hasAccess(): featureCode=", featureCode);
			return this.globalStore.currentUser.hasFeatureAccess(featureCode)
		},





		/**
		 * Create a DebugLog record.
		 * @param {string} source Where/what in the portal is generating this log. Max length: 110 characters.
		 * @param {object} details An obect containing the detail to record. If using `detailsString`, set to null. Will be serialised to JSON in the record.
		 * @param {string} detailsString A specific string to record instead of the `details` object. If not used, set to null.
		 * @param {string} subType (optional) Sub-categorise this tye of log if necessary. Max length: 255 characters.
		 * @param {string} item1 (optional) An additional but shorter than `details` pieces of data that needs to be recorded for the log.
		 * @param {string} item1 (optional) An additional piece of data that can be quickly viewed and queried. Max length: 128 characters.
		 * @param {string} item2 (optional) An additional piece of data that can be quickly viewed and queried. Max length: 128 characters.
		 * @param {string} item3 (optional) An additional piece of data that can be quickly viewed and queried. Max length: 128 characters.
		 * @param {string} item4 (optional) An additional piece of data that can be quickly viewed and queried. Max length: 128 characters.
		 */
		recordDebugLog(source, details, detailsString, log = "", subType = "", item1 = "", item2 = "", item3 = "", item4 = "")
		{
			// I think we need to send the string, because the `details` object doesn't look like it's being deserialised or received properly on the server.
			if (!detailsString) detailsString = JSON.stringify(details);

			var requestData = {
				Source: source,
				Details: details,
				DetailsString: detailsString,
				SubType: subType,
				Log: log,
				Item1: item1,
				Item2: item2,
				Item3: item3,
				Item4: item4,
			};

			this.$root.callApi("/Debugger/General/RecordDebugLog", requestData, "POST")
			.then((responseData) => {
				//console.log("recordDebugLog(): responseData=", responseData);

				if (responseData == null) return; // Not successful

				// Nothing to record to. This is a background process.
			});
		},
		



	}
}
//const app = new Vue(appDefinition);
const app = createApp(appDefinition)





// `app.use(....)` goes here.
app.use(router);











// -------------------------------------------------------------------------------- //
// -------------------------------------------------------------------------------- //
/**
 * 					Import external and internal components
 */
// -------------------------------------------------------------------------------- //
// -------------------------------------------------------------------------------- //


// Ref: https://antoniandre.github.io/vue-cal/?ref=madewithvuejs.com
import VueCal from 'vue-cal'
import 'vue-cal/dist/vuecal.css'
app.component("vue-cal", VueCal);


// Ref: https://element-plus.org/en-US/guide/quickstart.html#full-import
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
app.use(ElementPlus);




// -------------------------------------------------------------------------------- //
/**
 * 	Register global layouts and components in layouts 
 * 	(because it's just easier)
 */

import EnvironmentBanner from "./components/environment-banner.vue";
app.component("environment-banner", EnvironmentBanner);

import GlobalProcessingOverlay from "./components/global-processing-overlay.vue";
app.component("global-processing-overlay", GlobalProcessingOverlay);

import ToastMessage from "./components/toast-message.vue";
app.component("toast-message", ToastMessage);


//TODO: Obsolete.
// import PrimaryTopNav from "./layouts/navigation/primary-top-nav.vue";
// app.component("primary-top-nav", PrimaryTopNav);

//TODO: Obsolete.
// import PrimaryTopNavItemDesktop from "./layouts/navigation/primary-top-nav-item-desktop.vue";
// app.component("primary-top-nav-item-desktop", PrimaryTopNavItemDesktop);

//TODO: Obsolete.
// import PrimaryTopNavItemMobile from "./layouts/navigation/primary-top-nav-item-mobile.vue";
// app.component("primary-top-nav-item-mobile", PrimaryTopNavItemMobile);



import PublicLayout from "./layouts/PublicLayout.vue";
app.component("public-layout", PublicLayout);

import DomainConsistentLayout from "./layouts/DomainConsistentLayout.vue";
app.component("primary-domain-consistent-layout", DomainConsistentLayout);

//TODO: Obsolete.
// import PrimaryLayout from "./layouts/PrimaryLayout.vue";
// app.component("primary-layout", PrimaryLayout);


// A 2023, new design replacement for `PrimaryLayout.vue` with side nav.
// Once built and test on a handful of pages, rename it to Primary2Layout/primary-layout/PrimaryLayout.vue
// and make the original obsolete.
import Primary2Layout from "./layouts/Primary2Layout.vue";
app.component("primary2-layout", Primary2Layout);

// For pages embedded in a view view within the native app
import MobileAppEmbeddedViewLayout from "./layouts/MobileAppEmbeddedViewLayout.vue";
app.component("mobileappembeddedview-layout", MobileAppEmbeddedViewLayout);

// The browser version of the mobile app (full layout, replicate the native app [and then some])
import MobileAppLayout from "./layouts/MobileAppLayout.vue";
app.component("mobileapp-layout", MobileAppLayout);

import KnowledgebaseLayout from "./layouts/KnowledgebaseLayout.vue";
app.component("knowledgebase-layout", KnowledgebaseLayout);

import NoLayout from "./layouts/NoLayout.vue";
app.component("no-layout", NoLayout);


import Breadcrumbs from "./layouts/Breadcrumbs.vue";
app.component("c-breadcrumbs", Breadcrumbs);

//TODO: Obsolete.
// import RosteredLayout from "./layouts/RosteredLayout.vue";
// app.component("rostered-layout", RosteredLayout);



//import RegisterElements from "./register-global-elements.js";	//This works. Not sure how.
//-- Buttons
import cButton from "./components/buttons/button.vue"; app.component("c-button", cButton);
import cButtonStandard from "./components/buttons/button-standard.vue"; app.component("c-button-standard", cButtonStandard);
import cButtonConfirm from "./components/buttons/button-confirm.vue"; app.component("c-button-confirm", cButtonConfirm);
import cButtonSecondary from "./components/buttons/button-secondary.vue"; app.component("c-button-secondary", cButtonSecondary);
import cButtonTertiary from "./components/buttons/button-tertiary.vue"; app.component("c-button-tertiary", cButtonTertiary);
import cButtonDanger from "./components/buttons/button-danger.vue"; app.component("c-button-danger", cButtonDanger);
import cButtonLink from "./components/buttons/button-link.vue"; app.component("c-button-link", cButtonLink);
import cButtonCancel from "./components/buttons/button-cancel.vue"; app.component("c-button-cancel", cButtonCancel);

import cActionButton from "./components/buttons/action-button.vue"; app.component("c-action-button", cActionButton);

import cLotusButton from "./components/buttons/lotus-button.vue"; app.component("c-lotus-button", cLotusButton);

import cUtilityButton from "./components/buttons/utility-button.vue"; app.component("c-utility-button", cUtilityButton);
import cUtilityButtonDate from "./components/buttons/utility-button-date.vue"; app.component("c-utility-button-date", cUtilityButtonDate);

import cActionLink from "./components/buttons/action-link.vue"; app.component("c-action-link", cActionLink);

import cButtonSpace from "./components/buttons/button-space.vue"; app.component("c-button-space", cButtonSpace);

import cLotusOnly from "./components/lotus-only.vue"; app.component("lotus-only", cLotusOnly);

//-- Layout
import cLayoutCol from "./components/layouts/col.vue"; app.component("c-col", cLayoutCol);
import cLayoutFormItem from "./components/layouts/form-item.vue"; app.component("c-form-item", cLayoutFormItem);
import cLayoutGrid from "./components/layouts/grid.vue"; app.component("c-grid", cLayoutGrid);
import cLayoutRow from "./components/layouts/row.vue"; app.component("c-row", cLayoutRow);
import cLayoutSpacer from "./components/layouts/spacer.vue"; app.component("c-spacer", cLayoutSpacer);

//-- Forms
import cForm from "./components/forms/form.vue"; app.component("c-form", cForm);

import cInputCheckbox from "./components/forms/input-checkbox.vue"; app.component("c-input-checkbox", cInputCheckbox);
import cInputDate from "./components/forms/input-date.vue"; app.component("c-input-date", cInputDate);
import cInputDateRange from "./components/forms/input-date-range.vue"; app.component("c-input-date-range", cInputDateRange);
import cInputRadio from "./components/forms/input-radio.vue"; app.component("c-input-radio", cInputRadio);
import cInputRange from "./components/forms/input-range.vue"; app.component("c-input-range", cInputRange);
import cInputNumber from "./components/forms/input-number.vue"; app.component("c-input-number", cInputNumber);
import cInputSelect from "./components/forms/input-select.vue"; app.component("c-input-select", cInputSelect);
import cInputText from "./components/forms/input-text.vue"; app.component("c-input-text", cInputText);
import cInputPassword from "./components/forms/input-password.vue"; app.component("c-input-password", cInputPassword);
import cInputTextArea from "./components/forms/input-textarea.vue"; app.component("c-input-textarea", cInputTextArea);
import cInputEmail from "./components/forms/input-email.vue"; app.component("c-input-email", cInputEmail);
import cInputTime from "./components/forms/input-time.vue"; app.component("c-input-time", cInputTime);
import cInputLabel from "./components/forms/input-label.vue"; app.component("c-input-label", cInputLabel);
import cExpandingText from "./components/forms/expanding-text.vue"; app.component("expanding-text", cExpandingText);	//TODO: Prefix with `c-`
import cRequireField from "./components/forms/required-field.vue"; app.component("c-required-field", cRequireField);
import cFieldValidationResult from "./components/forms/field-validation-result.vue"; app.component("c-field-validation-result", cFieldValidationResult);
import cSelectCountry from "./components/forms/select-country.vue"; app.component("c-select-country", cSelectCountry);
import cSelectCurrency from "./components/forms/select-currency.vue"; app.component("c-select-currency", cSelectCurrency);
import cSelectAddress from "./components/forms/select-address.vue"; app.component("c-select-address", cSelectAddress);

import cReadOnlyField from "./components/forms/readonly-field.vue"; app.component("c-readonly-field", cReadOnlyField);

import cCommentText from "./components/forms/comment-text.vue"; app.component("c-comment-text", cCommentText);


//-- Typography (and links)
import cHeading from "./components/typography/heading.vue"; app.component("c-heading", cHeading);
import cLink from "./components/typography/link.vue"; app.component("c-link", cLink);
import cPageTitle from "./components/typography/page-title.vue"; app.component("page-title", cPageTitle);
import cPageSubTitle from "./components/typography/page-sub-title.vue"; app.component("page-sub-title", cPageSubTitle);
import cPageSectionTitle from "./components/typography/page-section-title.vue"; app.component("page-section-title", cPageSectionTitle);
import cOriginalYellowTitle from "./components/typography/original-yellow-title.vue"; app.component("original-yellow-title", cOriginalYellowTitle);
import cPageHeader from "./components/page-header.vue"; app.component("page-header", cPageHeader);
import cInfoText from "./components/typography/info-text.vue"; app.component("c-info-text", cInfoText);

//-- Tables
import cColumnHeader from "./components/tables/column-header.vue"; app.component("column-header", cColumnHeader);	// Used to be in Typography, but it's not really suited for that.


//-- Graphics & Iconography
import cLotusIcon from "./components/graphics/lotus-icon.vue"; app.component("c-lotus-icon", cLotusIcon);
import cIcon from "./components/graphics/icon.vue"; app.component("c-icon", cIcon);
import cIconWithDropdown from "./components/graphics/icon-with-dropdown.vue"; app.component("c-icon-with-dropdown", cIconWithDropdown);
import cImg from "./components/graphics/img.vue"; app.component("c-img", cImg);
import cLogo from "./components/graphics/logo.vue"; app.component("c-logo", cLogo);
import cIconWithText from "./components/graphics/icon-with-text.vue"; app.component("icon-with-text", cIconWithText);


import cLayoutContainer from "./components/layout-container.vue"; app.component("c-layout-container", cLayoutContainer);


//-- Components
import cAccordionPanel from "./components/accordion-panel.vue"; app.component("accordion-panel", cAccordionPanel);
import cScrollArrow from "./components/scroll-arrow.vue"; app.component("scroll-arrow", cScrollArrow);
import cHealthLight from "./components/health-light.vue"; app.component("health-light", cHealthLight);
import cQuickInfoWidget from "./components/quick-info-widget.vue"; app.component("quick-info", cQuickInfoWidget);

import cBucketSelect from "./components/bucket-select.vue"; app.component("bucket-select", cBucketSelect);
import cTaskSelect from "./components/task-select.vue"; app.component("task-select", cTaskSelect);

import cPagination from "./components/pagination.vue"; app.component("c-pagination", cPagination);
import cResultsFolder from "./components/results-filter.vue"; app.component("c-results-filter", cResultsFolder);

import cSimpleDatatable from "./components/simple-datatable.vue"; app.component("simple-datatable", cSimpleDatatable);

import cModal from "./components/modal.vue"; app.component("c-modal", cModal);
import cModal2 from "./components/modal2.vue"; app.component("c-modal2", cModal2);


import cTabs from "./components/tabs/tabs.vue"; app.component("c-tabs", cTabs);
import cTab from "./components/tabs/tab.vue"; app.component("c-tab", cTab);



import simpleTable from "./components/tables/simple-table.vue"; app.component("simple-table", simpleTable);
import simpleForm from "./components/forms/simple-form-renderer.vue"; app.component("simple-form", simpleForm);




 


//-- CSS & SCSS
import './assets/sass/helpers.scss';
import './assets/sass/forms.scss';
import './assets/sass/design.scss';	//TOOD: Not sure if this is actually needed. Disabled to see what happens... Ooooh, yeah - it's needed/
import "/assets/sass/_global.scss";
import '/assets/sass/_bootstrap-overrides.scss';
import '/assets/sass/_element-ui-overrides.scss';








// -------------------------------------------------------------------------------- //
/**
 * 	Setup Rollbar
 */




// console.log("config.GetEnvironmentCode()=", config.GetEnvironmentCode());

var canLogRollbarInThisEnvironment = true;
if (config.GetEnvironmentCode() == "development")
{
	canLogRollbarInThisEnvironment = false;
}

if (canLogRollbarInThisEnvironment == true)
{
	// Ref: https://docs.rollbar.com/docs/rollbarjs-configuration-reference

	// Set the Rollbar instance in the Vue prototype
	// before creating the first Vue instance.
	// This ensures it is available in the same way for every
	// instance in your app.
	app.config.globalProperties.$rollbar = new Rollbar({
		accessToken: '340f2a7dc2b140458875c541161119c3',
		captureUncaught: true,
		captureUnhandledRejections: true,
		payload: {
			environment: config.GetEnvironmentCode() + ":portal",
			person: {
				id: globalStore.state.currentUser.userId,
				username: globalStore.state.currentUser.displayName
				//email: globalStore.state.currentUser.userId
			}
		}
	});


	// If you have already set up a global error handler,
	// just add `vm.$rollbar.error(err)` to the top of it.
	// If not, this simple example will preserve the app’s existing
	// behavior while also reporting uncaught errors to Rollbar.
	app.config.errorHandler = (err, vm, info) => {
		vm.$rollbar.error(err);
		throw err; // rethrow
	};
}

app.config.compilerOptions.whitespace = 'preserve';



















// Finish with a mount
let portalAppInstance = app.mount('#app');


export { portalAppInstance }